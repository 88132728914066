import React from 'react';
import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';

const Post1 = ({ theme }) => (
  <Box>
    <Box {...theme?.contentPadding}>
      <Typography variant={'subtitle1'}>
        In 2011, Justin.tv debuted{' '}
        <Link
          href="https://en.wikipedia.org/wiki/Twitch_(service)"
          target="_blank"
          rel="noreferrer"
        >
          Twitch
        </Link>{' '}
        launched officially in public, in San Francisco's Financial District,
        which was able to capitalize on the momentum of livestreaming and
        growing video consumption in general. It quickly became the dominant
        platform for video game streamers. By 2020, Twitch had more than
        9.7million active streamers every month.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Today, Twitch users continue to engage on the platform for about 95
        minutes per day, allowing many streamers to find their communities,
        become full-time streamers, thought leaders, influencers, entertainers,
        and marketers for the various games, hardware, and software designed for
        live-streaming.
      </Typography>
      <br />
      <br />
      The best and most integral part about joining a livestream is its
      “liveness” — the unscripted “you never know what&apos;s gonna happen
      next,” the chat riffing at the same time, experiencing an authenticity
      livestream does well. But watching a 3 hours stream afterwards yourself
      without being able to engage with anyone is a bit like.....hanging out at
      a sports bar after the championship game ended.
      <br />
      <br />
      <Box {...theme?.contentPadding} {...theme?.contentImageContainer}>
        <Box
          {...theme?.contentImage}
          component="img"
          src="https://media.giphy.com/media/gioXyl9A3eiObmtwKZ/giphy.gif"
          alt="test-gif"
        />
      </Box>
      <br />
      <br />
      <Typography variant={'body1'}>
        Enterprising streamers came to this same realization - that their
        followers who missed the stream rarely watched the entirety after — and
        realized the power of making highlights from those streams. Those 10-20
        minute videos could both catch up viewers and bring in new people who
        happened upon them and became intrigued. Highlight videos grew their
        subscriber base quickly, and indeed, making highlight videos and sharing
        them on social became the rule of thumb for streamers.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        BUT back in those days it was really really really really really hard to
        make those videos from a stream. So even though it was important, very
        few people actually did it.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        The steps was incredibly time-consuming, wearying, and hard.
      </Typography>
      <br />
      <br />
      <Box {...theme?.contentPadding} {...theme?.contentImageContainer}>
        <Box
          {...theme?.contentImage}
          component="img"
          src="https://media.giphy.com/media/Up6yHMejD925tfHpRL/giphy.gif"
          alt="test-gif"
        />
      </Box>
      <br />
      <br />
      <Typography variant={'body1'}>
        First you had to download your entire Twitch VOD video. Which required
        first enabling 7 steps to save them. Then you actually had to download
        and store those files somewhere. It could take hours; a three hour
        stream, which was short those days, might take at least an hour or two.
        They were huge files so extra drives were needed. A few solutions popped
        up to make JUST that part faster.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Oh, and if you wanted to download chat, that was a separate thing. It
        wasn&apos;t synched with the video. So again people tried to create
        short programs to band-aid help just that part.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        So, now you had these huge files, which already took a few hours, IF
        they worked on the first or second attempt downloading. Now you had to
        edit these hours of footage. If you Googled “how to edit Twitch streams”
        you might have come upon videos like Alpha Gaming&apos;s How to Edit
        your Twitch Streams into YouTube Videos, and you think: Ok, that seems
        doable.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        At one point in Alpha Gaming&apos;s video, the editor says to watch the
        CLIP in its entirety. A clip might be a few minutes long. That&apos;s
        one clip. But these videos missed a crucial step before that. How do you
        even find the clips you need from the hours of content you just
        downloaded? Have you ever tried to slog through even one hour of video?
        And how long would it take to find clips for a 10 min video? Here&apos;s
        someone on Quora. clearly pained and intimidated before even starting
        the process, and the responses felt like editors in battle, laughing
        through tears.
      </Typography>
      <br />
      <br />
      <Box {...theme?.contentPadding} {...theme?.contentImageContainer}>
        <Box
          {...theme?.contentImage}
          component="img"
          src="https://s3.us-west-2.amazonaws.com/secure.notion-static.com/3741fc5e-a46e-4879-bdb8-fc573a3c9e0a/Screen_Shot_2022-05-07_at_8.07.26_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220509%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220509T220437Z&X-Amz-Expires=86400&X-Amz-Signature=d796b927cfbc26f1608330112b65e5c3a6af74f82ceb0deac0bc4228d59ab7cb&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-05-07%2520at%25208.07.26%2520PM.png%22&x-id=GetObject"
          alt="test-gif"
        />
      </Box>
      <br />
      <br />
      <Typography variant={'body1'}>
        Indeed, to produce anything of quality, it took many hours up to weeks
        to find clips from each stream.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        And after the tedious clip-finding stage, you then needed to have some
        previous technical savvy with the video editing software out there, like
        Final Cut, Premiere, Da Vinci Resolve, or have the patience to learn a
        new software. You&apos;ve probably met someone who has tried their hand
        at video editing. Their voices often fades after they mention they have
        some version of a software, downloaded it but then.....
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        And remember, this is for just one stream. So if you streamed multiple
        times a week, you&apos;d just have to decide which one you were willing
        to pour all your blood, sweat and tears. Some masochistic YouTubers said
        it took up to 80+ hours to create one video. There was just lot of hard
        work, time, and eye and forearm fatigue involved. If you took too long
        to complete your highlight, your video risked being already irrelevant
        and stale by the time it was published. Or if you could only create a
        few videos a year, you couldn&apos;t establish any meaningful engagement
        and expectations with your followers.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        But, if, in Twitch&apos;s infancy, you did manage to be one of the
        fervent, masochistic and committed few — the Navy SEALS and the Green
        Berets of video editing— these YouTube channels drew large audiences
        quickly. These highlight videos of high level, entertaining play, made
        gaming feel more mainstream and attract the numerous amateur players who
        would watch the streams and highlights to learn new skills and gain
        insights in the games.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        In 2017, Twitch introduced Twitch clips, where fans could grab snippets.
        Long form videos on YouTube was still king, but at least fans and
        moderators watching a stream live could mark exciting moments. Streamers
        still had to download each clip to create any kind of compilation, but
        at least this acknowledged that highlights were popular.. And around
        this time, other software companies also attempted to Shorten The Way,
        because the demand for video creation was so great, and yet there were
        few solutions to help all the streamers drowning in footage.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        In 2018, Flixier came out so that you didn&apos;t have to download the
        entirety of the VOD.
        https://flixier.com/blog/how-to-edit-your-twitch-streams-without-downloading-them
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Now, if your stream was longer than an hour, it would chunk them into
        one hour segments, but this was a good start in chipping away at the
        problems. But you&apos;d still have to slog through each hour to find
        the clips and then download the clips in your video editing software.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Then in 2019, medal.tv came out to to address the problem of “finding
        clips in hours of footage” exclusively for gamers. It offered
        suggestions on best game play. And around the same time, the team at
        Athenascope would identify key moments and game events in a variety of
        games, which was great if you were playing those games, but if you
        didn&apos;t, you&apos;d have to keep waiting.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        But alongside the boom in video game streamers and viewers, were many
        other people getting into livestreams with lots of process-oriented
        content, like cooking, art, dancing, and other kinds of dancing. Twitch
        added the category Just chatting in 2018 to accommodate this burgeoning
        group of content creators. And in 2020, as the pandemic shut down live
        events, musicians turned to livestreaming as a way to keep connecting to
        their audiences. Many in these also wanted to create highlights, but
        they had to do it the old fashioned way because any software targeted
        for gamers couldn&apos;t offer a more generalized solution.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        So even when every marketing blog screams: YOU NEED TO MAKE HIGHLIGHTS
        FROM YOUR VIDEOS, most people decided spending 6 hours editing after a
        4-5 hour livestream was just too much, and all their video content just
        kinda disappeared into the haze of the internet.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        In 2022, Catapult came onto this scene. It wanted to solve the hard
        problem of dealing with giant files and finding content in hours of
        footage. Catapult&apos;s core premise was that humans had the ingenuity,
        innovative spirit, and distinct voices that AI generated content
        couldn&apos;t yet do. So unlike a solely AI-automated way to find clips,
        Catapult was designed to make it easy for a person to find the content
        they uniquely wanted.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Catapult retrieves the raw footage with a simple copy and paste of video
        links stored in Twitch, YouTube, Dropbox, Google Drive, and Vimeo. Now
        there was never any downloading of hours of video. The intuitive
        features gave ways to scan quickly and suggest potentially interesting
        moments to make clips quickly. From there it was a matter of importing
        those clips into Canva, a simple, template based, drag and drop video
        editor, where adding text, music, transitions and layouts was also
        intuitive.
      </Typography>
      <br />
      <br />
      <Typography variant={'body1'}>
        Being able to make highlight and promo videos easily now gives a bigger
        spotlight to the huge diversity of voices, backgrounds, personalities
        and stories emerging from all the video content. It&apos;s an exciting
        and dynamic time to be creating video content, and finally the tools to
        be able to support that.
      </Typography>
    </Box>
  </Box>
);

export default Post1;
