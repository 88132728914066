import * as React from 'react';
import { graphql } from 'gatsby';
import { BlogArticle } from 'views';

import posts from 'views/Catapult/components/Blog/posts';
// Destructuring on props to clearly see what we are getting.
const Post = ({
  data: {
    post: { name, ownerName, ownerAvatar, postDate, componentName },
  },
}) => (
  <BlogArticle
    postData={{ name, ownerName, ownerAvatar, postDate }}
    Content={posts[componentName]}
  />
);

export default Post;

export const query = graphql`
  query($name: String!) {
    post(name: { eq: $name }) {
      name
      componentName
      ownerName
      ownerAvatar
      postDate
    }
  }
`;
